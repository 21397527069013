<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section
        class="row border-bottom items-center full-width q-py-xs q-pl-none"
      >
        <q-legend :label="$t('Reports')" text-class="text-h6" />

        <search dense autoset is-expandable @submit="handleSearch" />

        <q-space />

        <q-btn
          color="dark"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="$t('Filter')"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          @click="onRequest"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          criteria: criteriaFields,
          values: {
            states: statuses,
          },
          style: {
            noGroups: true,
          },
        }"
        @submit="handleFiltersSubmit"
        @close="openCloseFilters"
      />

      <q-card-section class="q-pa-none">
        <q-table
          style="height: calc(100vh - 130px)"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="reports"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="reportsLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="handleDBClick(props.row)"
            >
              <q-td key="id" :props="props">
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
                style="
                  max-width: 200px;
                  min-width: 170px;
                  white-space: pre-wrap !important;
                "
              >
                <div class="text-subtitle2 q-my-xs">
                  <span>{{ props.row.handler }}</span>
                </div>

                <div v-if="props.row.name" class="q-mb-xs text-caption">
                  <span>{{ props.row.name }}</span>
                </div>

                <div class="text-caption">
                  <span>{{ $t("Start day") }} -</span>

                  <span>
                    {{
                      $moment(
                        typeof props.row.beginningPeriod === "object"
                          ? props.row.beginningPeriod.date
                          : props.row.beginningPeriod
                      ).format(appOptions.formats.date)
                    }}
                  </span>
                </div>

                <div class="text-caption">
                  <span>{{ $t("End day") }} -</span>

                  <span>
                    {{
                      $moment(
                        typeof props.row.endPeriod === "object"
                          ? props.row.endPeriod.date
                          : props.row.endPeriod
                      ).format(appOptions.formats.date)
                    }}
                  </span>
                </div>
              </q-td>

              <q-td key="owner" :props="props">
                <span v-if="props.row._embedded && props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>
              </q-td>

              <q-td key="date" :props="props">
                {{
                  $moment(
                    typeof props.row.created === "object"
                      ? props.row.created.date
                      : props.row.created
                  ).format(appOptions.formats.internationalDate)
                }}
              </q-td>

              <q-td key="state" :props="props">
                <q-badge
                  :color="stateColors[props.row.state]"
                  class="q-pa-sm text-capitalize"
                >
                  {{ $t(stateNames[props.row.state] || props.row.state) }}
                </q-badge>
              </q-td>

              <q-td key="documents" :props="props" auto-width>
                <q-btn-dropdown
                  v-if="props.row.state !== 'new'"
                  :color="
                    getTemplates(props.row.data).length > 0
                      ? 'light-blue-9'
                      : 'grey'
                  "
                  size="sm"
                  :disable="getTemplates(props.row.data).length <= 0"
                >
                  <q-list v-if="props.row.data">
                    <q-item
                      v-for="template in getTemplates(props.row.data)"
                      :key="template.id"
                      v-close-popup
                      clickable
                      @click="handleTemplate(template,props.row.id)"
                    >
                      <q-item-section>
                        <q-item-label>
                          {{
                            `${template.name || $t("No name")} (${template.id})`
                          }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>

                <div v-else class="text-caption">
                  <span class="q-mr-sm">
                    {{ $t("Please wait...") }}
                  </span>

                  <q-icon name="error_outline" color="warning" size="1.4rem" />

                  <q-tooltip class="q-px-sm text-left">
                    <ul class="q-ma-none q-pl-md" style="max-width: 350px">
                      <li class="text-caption text-weight-light">
                        {{
                          $t(
                            "Reports can take several minutes to up to 2 hours to generate, depending on the size of the data set. However, for particularly large data sets, it can take longer."
                          )
                        }}
                      </li>

                      <li class="text-caption text-weight-light">
                        {{
                          $t(
                            "If the status of your report does not update in 24 hours, or has been in a Pending status for 24 hours, please contact OA Support."
                          )
                        }}
                      </li>
                    </ul>
                  </q-tooltip>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin.vue'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'Reports',
  components: {
    Search,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Details'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Requested On'),
          name: 'date',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Download'),
          name: 'documents',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        completed: 'accent',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark',
        closed: 'positive'
      },
      stateNames: {
        new: 'Requested',
        completed: 'Pending',
        closed: 'Ready'
      },
      statuses: [
        { id: 'new', title: this.$t('Requested') },
        { id: 'completed', title: this.$t('Pending') },
        { id: 'confirmed', title: this.$t('Confirmed') },
        { id: 'closed', title: this.$t('Ready') }
      ],
      criteriaFields: [
        'report'
      ],
      activatedFields: [
        'id',
        'owner',
        'warehouse',
        'state',
        'created.from',
        'created.to'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'reports',
      'reportsLoading',
      'appOptions'
    ])
  },
  mounted () {
    this.onRequest({})
  },
  methods: {
    ...mapActions([
      'loadReports'
    ]),
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleDBClick () {
    },
    getTemplates (data) {
      if (!data) {
        return []
      }

      if (data.files) {
        return Object.entries(data.files || {}).map(([id, file]) => ({ id, file }))
      }

      if (data.templates) {
        if (Array.isArray(data.templates)) {
          return data.templates
        }

        return Object.values(data.templates)
      }

      if (data.documents) {
        if (!Array.isArray(data.documents)) {
          return Object.values(data.documents)
        }

        return data.documents
      }

      return []
    },
    handleTemplate (template,reportId) {
      this.$service.report.download(template, template.contentType,reportId)
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, per_page: 25, page: 1 } })
    },
    handleSearch (search) {
      const pagination = {
        per_page: 25,
        page: 1,
        search
      }

      return this.onRequest({ pagination })
    },
    create () {
      const reportType = ((this.serverParams.filter || []).find(x => x.field === 'reportType') || {}).value
      this.$router.push(`/documents/reports/reports/entity/${reportType}`)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      const newQuery = {
        ...query,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ]
      }

      this.updateParams(newQuery)

      return this.loadReports(this.serverParams)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    }
  }
}
</script>
